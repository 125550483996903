$(function () {
  $(".comment-form .form-control").change(function () {
    if ($(this).val().length) {
      $(this)
        .parent()
        .addClass("active");
    } else {
      $(this)
        .parent()
        .removeClass("active");
    }
  });

  $(".search-box form .form-control").change(function () {
    if ($(this).val().length) {
      $(this)
        .parent()
        .parent()
        .addClass("active");
    } else {
      $(this)
        .parent()
        .parent()
        .removeClass("active");
    }
  });
})

function changeVideoMobDesk(selector) {
  let viewport = window.screen.width;
  let videoEl = document.querySelector(selector);

  if (videoEl) {
    let videoMob = videoEl.getAttribute('data-video-mob');
    let videosDesk = videoEl.getAttribute('data-video-desk');
    
    if (viewport >= 1024) {
      $(`${selector} source`).attr('src', videosDesk);
    } else {
      $(`${selector} source`).attr('src', videoMob);
    }
    $(selector)[0].load()
  } 
}

function handleOpenSearch() {
  let header = document.querySelector('header');
  let body = document.querySelector('body');
  let buttonOpenSearch = document.querySelector('.toggle-search');
  let buttonOpenSearchMob = document.querySelector('.js-toggle-search');
  let navbarToggle = document.querySelector('.navbar-toggler');

  buttonOpenSearch.addEventListener('click', function () {
    body.classList.add('active-search-bar');
    body.classList.remove('mobile');
  }, true)

  buttonOpenSearchMob.addEventListener('click', function () {
    body.classList.add('active-search-bar');
    body.classList.remove('mobile');
    header.classList.remove('menu-active');
    navbarToggle.classList.remove('active');
  }, true)
}

function handleCloseSearch() {
  let body = document.querySelector('body');
  let buttonCloseSearch = document.querySelector('.close-search-box');
  let inputSearchBox = document.querySelector('.form-control-search');

  buttonCloseSearch.addEventListener('click', function () {
    body.classList.remove('active-search-bar');
    inputSearchBox.value = '';
  }, false)
}

let clipboardShare = function () {

  let btnClipboard = document.querySelector('.js-clipboard');
  let btnClipboardMessage = document.querySelector('.js-clipboard-message');

  if (!btnClipboard) return;

  btnClipboard.addEventListener('click', function (event) {
    event.preventDefault();
    let copyTextAttr = this.getAttribute('data-url');

    let textArea = document.createElement("textarea");
    textArea.value = copyTextAttr;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      btnClipboardMessage.classList.add('active');
      setTimeout(() => {
        btnClipboardMessage.classList.remove('active');
      }, 2000);
    } catch (err) {
    }

    document.body.removeChild(textArea);
  });

}

function fixedMenuFlutuante(offset) {
  var scrollPos = window.scrollY
  var navbar = document.querySelector('.navbar-bottom')

  window.addEventListener('scroll', function () {
    scrollPos = window.scrollY
    passive = true

    if (scrollPos >= offset) {
      navbar.classList.add('active')
    } else {
      navbar.classList.remove('active')
    }
  })
}

function openMenuMob() {
  var menuButton = document.querySelector('.navbar-toggler')
  // var menuContent = document.querySelector('.navbar')
  var header = document.querySelector('.header')
  var body = document.querySelector('body')

  menuButton.addEventListener('click', function () {
    menuButton.classList.toggle('active')
    // menuContent.classList.toggle('active')
    header.classList.toggle('menu-active')
    body.classList.remove('active-search-bar')
  })
}

// init jquery code
$(document).ready(function () {
  handleOpenSearch();
  handleCloseSearch();
  clipboardShare();
  changeVideoMobDesk("#video-home");
  fixedMenuFlutuante(50);
  openMenuMob()
})